<template>
	<div class="footer_view">
		<div class="footer_content">
			<div class="logo_box">
				<img src="@/assets/footer/footer_logo_2.png" alt="" class="footer_logo">
			</div>
			<div class="menu_list_box">
				<div class="menu_list">
					<div class="menu_title">产品</div>
					<div class="menu_item" v-for="nav in productList" :key="nav.text" @click="onNavClick(nav.path)">{{ nav.text }}</div>
				</div>
				<div class="menu_list">
					<div class="menu_title">资源</div>
					<div class="menu_item" v-for="nav in resoures" :key="nav.text" @click="onNavClick(nav.path)">{{ nav.text }}</div>
				</div>
				<div class="menu_list">
					<div class="menu_title">工作流</div>
					<div class="menu_item" v-for="nav in works" :key="nav.text" @click="onNavClick(nav.path)">{{ nav.text }}</div>
				</div>
				<div class="menu_list">
					<div class="menu_title">企业服务</div>
					<div class="menu_item" v-for="nav in server" :key="nav.text" @click="onNavClick(nav.path)">{{ nav.text }}</div>
				</div>
				<div class="menu_list">
					<div class="menu_title">关于我们</div>
					<div class="menu_item" v-for="nav in about" :key="nav.text" @click="onNavClick(nav.path)">{{ nav.text }}</div>
				</div>
			</div>
			<div class="mobile_content">
				<img src="@/assets/footer/web_logo.png" alt="" class="weblogo">
				<div class="mobile_content_text">用电脑浏览shmofine.com了解更多</div>
			</div>
		</div>
		<copyRight></copyRight>
	</div>
</template>

<script>
	import copyRight from './copy_right.vue'
	import { productList, resoures, works, server, about } from './navOptions.js'
	
	export default {
		components: {
			copyRight
		},
		data: () => ({
			productList,
			resoures,
			works,
			server, 
			about
		}),
		methods: {
			onNavClick(path) {
				if (path) {
					this.onLink(path)
				} else {
					this.$message.default('正在开发中,敬请期待！')
				}
			},
		}
	}
</script>

<style lang="less" scoped>
	.footer_view{
		background-color: rgba(26, 26, 26, 1);
;
		width:100%;
		.footer_content{
			width: 1441px;
			margin:0 auto;
			display: flex;
			.logo_box{
				padding-top:.57rem;
				padding-right: .28rem;
				border-right:1px solid rgba(119, 124, 132, 0.2);
				.footer_logo{
					width: 2.12rem;
					height: 1.28rem;
				}
			}
			.menu_list_box{
				display: flex;
				color: #fff;
				width: 100%;
				padding-top: .57rem;
				padding-left: .47rem;
				// border-left: 1px solid #222;
				justify-content: space-between;
				padding-bottom: .52rem;
				.menu_list{
					font-size: .16rem;
					line-height: .32rem;
					.menu_title{
						font-size: 28px;
						margin-bottom: 42px;
					}
					.menu_item{
						cursor: pointer;
						color: #777c84;
						&:hover{
							color: #fff;
						}
					}
					.icon_list{
						display: flex;
						margin-top: 23px;
						.icon_img_box{
							width: .24rem;
							position: relative;
							margin-right: .33rem;
							line-height: 1;
							&:hover{
								.icon_hover_box{
									display: flex;
								}
							}
						}
						.icon_img{
							width: 100%;
							display: block;
							cursor: pointer;
							line-height: 1;
						}
						.icon_hover_box{
							display: none;
							position: absolute;
							width: 129px;
							height: 122px;
							background: #fff;
							border-radius: 8px;
							bottom: calc(100% + 18px);
							left: 50%;
							transform: translateX(-70%);
							z-index: 999;
							justify-content: center;
							align-items: center;
							position: relative;
							.icon_hover_img{
								width: 110px;
								display: block;
								position: relative;
								z-index: 1;
								line-height: 1;
							}
							.icon_hover_text{
								text-align: center;
								position: absolute;
								bottom: .24rem;
								font-size: 14px;
								font-weight: 700;
								left:0;
								right:0;
								line-height: .14rem;
							}
							&:after{
								position: absolute;
								top: 100%;
								left: 70%;
								width: 26.38px;
								height: 26.38px;
								transform: translate(-50%, -70%) rotate(45deg) ;
								background-color: #fff;
								border-radius: 4px;
								display: block;
								content: '';
								z-index: 0;
							}
							&:before{
								position: absolute;
								top: 100%;
								left: 70%;
								width: 26.38px;
								height: 26.38px;
								transform: translate(-50%, -20%);
								background-color: transparent;
								border-radius: 4px;
								display: block;
								content: '';
								z-index: 0;
							}
						}
					}
				}
				
			}
			.mobile_content{
				display: none;
			}
		}
		.mobile_content{
			display: none;
		}
	}
</style>

<style lang="less" scoped>
	@media screen and (max-width: 760px) {
		.footer_view{
			.logo_box{
				display: none;
			}
			.footer_content{
				width: 100%;
				display: block;
				padding: 30px 0;
				.menu_list_box{
					display: none;
				}
				.mobile_content{
					display: flex;
					justify-content: center;
					align-items: center;
					flex-direction: column;
					.weblogo{
						width: 40px;
						display: block;
						margin-bottom: 22px;
					}
					.mobile_content_text{
						font-size: 16px;
						line-height: 22px;
						letter-spacing: 0.01em;
						color: rgba(171, 174, 180, 1);
						white-space: nowrap;
					}
				}
			}
		}
	}
	
	
</style>