	let mogineUrl =  '/mogine/'
	
	const productList= [
		// { text: 'Mogine 摩镜视界', 		name: 'wecome',		path: '/index' },
		{ text: 'MoStudio 摩力站', 		name: 'wecome',		path: '/wecome' },
		// { text: 'MoDesign 摩镜设计', 	name: '',			path: '/index', domain: 'mogine' },
		{ text: 'MoIdeaAI  摩镜创意',	name: 'MoAi',		path: '/MoAI' },
		{ text: 'MogineLab 摩镜光场',	name: 'lab',		path: '/lab/index' },
		// { text: 'MogineHab 摩镜世界',	name: 'Hab',		path: '/Hab/index' },
		{ text: 'MoHuman   数字人',		name: 'aiHuman',		path: '/digitalHuman' },
		// { text: 'MoLab 数智培训',		name: 'MoLab',		path: '/product/page/MoLab' },
		// { text: 'MoDigital 数智影业',	name: 'MoDigital',	path: '/product/page/MoDigital' },
		// { text: 'MoSpace 数字展厅',		name: 'MoSpace',	path: '/product/page/MoSpace' },
		// { text: 'Mo-ID 数字工厂',		name: 'MoID', 		path: '/product/page/MoID' },
		// { text: 'MoCity 摩镜空间',		name: 'MoCity',		path: '/product/page/MoCity' },
		
		// { text: 'MoCity 3D 摩镜地图', des: '国内首个城市级超写实三维全息地图',path: '' },
		// { text: 'Molndustry 摩镜工业', des: '厂房设计、运维模拟、虚拟培训、虚拟参访等。',path: '' },
		// { text: 'MoDigital 摩镜数智', des: 'MoDesign、M3元宇宙建筑、景观、室内、家具等',path: '' },
		// { text: 'MoEducation 摩镜教育', des: '数字教学（院校）',path: '' }
	]
	const resoures = [
		{ text: '官方资源', path: "#/list", domain: 'mogine' },
		{ text: '热门资源', path: "#/hotList", domain: 'mogine' },
		{ text: '我的资源', path: "#/works", domain: 'mogine' }
	]
	const works = [
		{ text: 'SketchUp', path: "" },
		{ text: '3ds Max', 	path: "" },
		{ text: 'Rhino', 	path: "" },
		{ text: 'Revit', 	path: "" },
		{ text: 'Archicad', path: "" },
		{ text: 'Cinema 4D', path: "" },
	]
	
	const server = [
		{ text: '解决方案' },
		{ text: '成功案例' },
		{ text: '联系我们' },
		{ text: '关于我们' },
	]
	
	const about = [
		{ text: '竞争优势' },
		{ text: '最新资讯' }
	]
	
	export {
		productList, resoures, works, mogineUrl, server, about
	}